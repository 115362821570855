var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"}),_vm._v(" 贈品管理 ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("贈品列表")])],1)],1)])]),_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('div',{staticClass:"col-12 d-flex col-xl-4 mb-1 mb-xl-0"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" 贈品列表 ")]),_c('div',{staticClass:"ml-4 py-2"},[_c('b-input-group',[_c('b-form-checkbox',{on:{"change":_vm.fetchGifts},model:{value:(_vm.isEnabled),callback:function ($$v) {_vm.isEnabled=$$v},expression:"isEnabled"}},[_vm._v("只包含已啟用")])],1)],1)]),_c('div',{staticClass:"\n            col-12 col-xl-6\n            d-flex\n            flex-column flex-xl-row\n            align-items-end align-items-xl-center\n          "},[_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2",attrs:{"variant":"primary","to":{name: 'GiftCreate'}}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v("新增贈品 ")]),_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"名稱"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchGifts(_vm.currentPage)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){return _vm.fetchGifts(_vm.currentPage)}}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.gifts,"fields":_vm.fields},on:{"sort-changed":_vm.handleSort},scopedSlots:_vm._u([{key:"cell(image_url)",fn:function(data){return [(
                      data.item.image_url
                    )?_c('b-img-lazy',{staticClass:"thumb-image",attrs:{"src":data.item.image_url,"alt":"商品圖片","width":"50","height":"50"}}):_vm._e()]}},{key:"cell(started_at_and_end_at)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.formatDate(data.item.available_start_at)))]),_c('div',[_vm._v(_vm._s(_vm.formatDate(data.item.available_end_at)))])]}},{key:"cell(branch)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(data.item.branch ? data.item.branch.name : "")+" ")]),_c('div',[_vm._v(" "+_vm._s(data.item.branch ? data.item.branch.branch_code : "")+" ")])]}},{key:"cell(arrange)",fn:function(data){return [(_vm.checkPermission([_vm.consts.GIFT_LIST_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-info","to":{
                name: 'GiftUpdateImage',
                params: {
                  gift_id: data.item.id,
                },
              }}},[_vm._v("設定圖片 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.GIFT_LIST_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
                name: 'GiftEdit',
                params: {
                  gift_id: data.item.id,
                },
              }}},[_vm._v("編輯 ")]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }