<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
              贈品管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>贈品列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <div class="col-12 d-flex col-xl-4 mb-1 mb-xl-0">
            <h4 class="font-weight-bold">
              贈品列表
            </h4>
            <div class="ml-4 py-2">
              <b-input-group>
                <b-form-checkbox v-model="isEnabled" @change="fetchGifts">只包含已啟用</b-form-checkbox>
              </b-input-group>
            </div>
          </div>
          <div
            class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-end align-items-xl-center
            "
          >
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
              variant="primary"
              :to="{name: 'GiftCreate'}"
            ><i class="fa fa-plus" aria-hidden="true"></i
            >新增贈品
            </b-button
            >

            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input placeholder="名稱" v-model="keyword"
                v-on:keyup.enter="fetchGifts(currentPage)"></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchGifts(currentPage)"><i class="fa fa-search"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table responsive striped hover :items="gifts" :fields="fields" @sort-changed="handleSort">
          <template #cell(image_url)="data">
            <b-img-lazy
              v-if="
                        data.item.image_url
                      "
              class="thumb-image"
              :src="
                        data.item.image_url
                      "
              alt="商品圖片"
              width="50"
              height="50"
            />
          </template>
          <template #cell(started_at_and_end_at)="data">
            <div class="mb-1">{{ formatDate(data.item.available_start_at) }}</div>
            <div>{{ formatDate(data.item.available_end_at) }}</div>
          </template>
          <template #cell(branch)="data">
            <div class="mb-1">
              {{ data.item.branch ? data.item.branch.name : "" }}
            </div>
            <div>
              {{ data.item.branch ? data.item.branch.branch_code : "" }}
            </div>
          </template>
          <template #cell(arrange)="data">
            <b-button
              class="ml-2"
              variant="inverse-info"
              v-if="checkPermission([consts.GIFT_LIST_MODIFY])"
              :to="{
                  name: 'GiftUpdateImage',
                  params: {
                    gift_id: data.item.id,
                  },
                }"
            >設定圖片
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-warning"
              v-if="checkPermission([consts.GIFT_LIST_MODIFY])"
              :to="{
                  name: 'GiftEdit',
                  params: {
                    gift_id: data.item.id,
                  },
                }"
            >編輯
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
          ></b-pagination>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>

import giftApi from "../../../apis/gift";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  data() {
    return {
      consts,
      keyword: null,
      showLoading: false,
      selectedMerchantID: null,
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      fields: [
        {
          key: "name",
          label: "贈品名稱",
          sortable: true,
        },
        {
          key: "image_url",
          label: "贈品圖片",
        },
        {
          key: "event_id",
          label: "活動ID",
        },
        {
          key: "point_need",
          label: "所需點數",
          sortable: true,
        },
        {
          key: "quantity",
          label: "贈品剩餘數量",
          sortable: true,
        },
        {
          key: "original_quantity",
          label: "贈品初始數量",
          sortable: true,
        },
        {
          key: "type",
          label: "贈品類型",
        },
        {
          key: "is_enabled",
          label: "狀態",
          formatter: (value) => {
            return value ? "啟用" : "停用"
          },
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      gifts: [],
      permissionChecker: new PermissionChecker(),
      sortBy: 'sort',
      orderBy: 'asc',
      isEnabled: true
    };
  },
  watch: {
    currentPage: function () {
      this.fetchGifts()
    }
  },
  mounted() {
    this.fetchGifts();
  },
  methods: {
    async fetchGifts() {
      this.showLoading = true;

      try {
        const params = {
          page: this.currentPage,
          sort_by: this.sortBy,
          order_by: this.orderBy,
          is_enabled: this.isEnabled ? 1 : 0,
        };
        if (this.keyword) params.keyword = this.keyword;

        const {data} = await giftApi.getGifts(params);

        this.gifts = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.error(error);
        alert("取得商品資料錯誤");
      }
      this.showLoading = false;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSort(ctx) {
      this.sortBy = ctx.sortBy;
      this.orderBy = ctx.sortDesc ? "desc" : "asc";
      this.currentPage = 1;
      this.fetchGifts();
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
